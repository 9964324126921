<template>
  <div class="container">
    <div v-if="count" class="count">今年共有{{ count }}个点值明细</div>
    <!-- 今年的明细 -->
    <el-table style="width: 100%" header-cell-class-name="tableHeader" :data="pointList">
      <el-table-column label="项目名称" prop="name" min-width="280px" />
      <el-table-column label="点值" prop="point" />
      <el-table-column label="加点日期" prop="create_at" align="right" />
    </el-table>
    <!-- 获得合计 -->
    <el-table style="width: 100%" :data="history_add_point" v-if="history_add_point.length">
      <el-table-column prop="name" />
      <el-table-column prop="point" align="right" />
    </el-table>
    <!-- 消费合计 -->
    <el-table style="width: 100%" :data="history_exchange_point" v-if="history_add_point.length">
      <el-table-column prop="name" />
      <el-table-column prop="point" align="right" />
    </el-table>
  </div>
</template>
<script>
import store from '@/store'
export default {
  data() {
    return {
      pointList: [],
      nowDate: new Date(),
      totle_point: 0,
      history_add_point: [],
      history_exchange_point: [],
      count: 0,
    }
  },
  created() {
    this.getPointDetail()
  },
  methods: {
    async getPointDetail() {
      store.dispatch('user/getPointDetail').then(res => {
        if (res.data.ret_code === 0) {
          this.pointList = res.data.data.list
          this.totle_point = res.data.data.totle_point
          this.history_add_point = res.data.data.history_add_point
          this.history_exchange_point = res.data.data.history_exchange_point
          this.count = res.data.data.count
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/ .el-table__row {
  .cell {
    font-size: 14px;
  }
}
.container {
  padding: 20px;
  margin: 0;
  background-color: #fff;
  box-sizing: border-box;

  .count {
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
  }
  .my-point {
    border: 1px solid #ddd;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    span:first-child {
      font-size: 14px;
      color: #333;
    }
    span:last-child {
      font-size: 18px;
      color: #ec7717;
    }
  }
}
</style>
